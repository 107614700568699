// eslint-disable-next-line
import React from 'react';
import './footer.css';

function Footer() {

    return (
        <>
            <div className='footer__block'>
                <div className='footer__logo' />
                <p className='footer__year'>2023</p>
            </div>
        </>
    );
}

export default Footer;