// eslint-disable-next-line
import React from 'react';
import './about.css';

function About() {
    return (
        <>
            <div className='about__block' id='aboutUs'>

                <h2 className='about__title'>О НАС</h2>

                <p className='about__description-paragraph'>
                    «NEUROMIND» — молодая и развивающаяся компания, которая включает в себя специалистов с уникальным опытом в IT-отрасли.
                    <br /> <br />
                    Мы стремимся продвигать использование искусственного интеллекта для решения реальных проблем и повышения качества жизни людей во всем мире.
                </p>

                <div className='about__stack'>

                    <div className='about__stack-element'>
                        <p className='about__stack-element-title'>01</p>
                        искусственный <br /> интеллект
                    </div>


                    <div className='about__stack-element'>
                    <p className='about__stack-element-title'>02</p>
                        разработка веб-сайтов  <br /> и веб-сервисов
                    </div>


                    <div className='about__stack-element'>
                    <p className='about__stack-element-title'>03</p>
                        разработка мобильных  <br /> приложений
                    </div>


                    <div className='about__stack-element'>
                    <p className='about__stack-element-title'>04</p>
                        DevOps
                    </div>

                </div>

            </div>
        </>
    );
}

export default About;