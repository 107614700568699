// eslint-disable-next-line
import React from 'react';
import './main.css';

function Main() {
    return (
        <>
            <main className='main'>
                <div className='main__information_block'>
                    <div className='main__image_group'>
                        <div className="main__image main__image_1"></div>
                        <div className="main__image main__image_2"></div>
                        <div className="main__image main__image_3"></div>
                    </div>

                    <div className='main__image-mobile-block'></div>

                    <h1 className='main__title'>NEUROMIND</h1>
                    <h2 className='main__subtitle'>Искусственный интеллект для реальной жизни</h2>
                </div>
            </main>
        </>
    );
}

export default Main;