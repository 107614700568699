import React from 'react';
import { NavLink } from "react-router-dom";
import './navigation.css';

function Navigation({ colorTheme = '', handleClickScroll }) {
    const checkboxRef = React.useRef();

    function closeMenu(e) {
        handleClickScroll(e);
        checkboxRef.current.checked = false;
    }

    return (
        <div className="navigation">

            <input className="navigation__toggle" id="navigation__toggle" type="checkbox" ref={checkboxRef} />
            <label className={`navigation__menu-button ${colorTheme === 'white' && 'navigation__menu-button_color_white'}`} htmlFor="navigation__toggle">
                <div className="navigation__menu-icon" />
            </label>


            <div className="navigation__menu-panel">

                <div className="navigation__logo" />

                <ul className="navigation__links-list">
                    <li>
                        <NavLink to="/" className="navigation__link" name='aboutUs' onClick={closeMenu}>
                            О НАС
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/" className="navigation__link" name='cases' onClick={closeMenu}>
                            КЕЙСЫ
                        </NavLink>
                    </li>

                    <li className="navigation__contact">
                        <p className='navigation__info-text'>Телефон</p>
                        <NavLink
                            to='#'
                            className='navigation__info-text_bold'
                            onClick={(e) => {
                                window.location.href = "tel:89969749708";
                                e.preventDefault();
                            }}
                        >
                            7-996-974-97-08
                        </NavLink>

                        <p className='navigation__info-text'>Почта</p>


                        <NavLink
                            to='#'
                            className='navigation__info-text_bold'
                            onClick={(e) => {
                                window.location.href = "mailto:neuromind@bk.ru";
                                e.preventDefault();
                            }}
                        >
                            neuromind@bk.ru
                        </NavLink>
                    </li>

                </ul>

            </div>

            <div className='navigation__background' />
        </div>
    )

}

export default Navigation;