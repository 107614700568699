import React from 'react';
import { BrowserRouter, NavLink } from "react-router-dom";
import './header.css';
import Navigation from './navigation'

function Header({ handleClickScroll }) {
    return (
        <>
            <header className="header">
                <div className="header__logo" />

                <div className="header__links">
                    <BrowserRouter>

                        <Navigation
                            handleClickScroll={handleClickScroll}
                        />

                        <NavLink to="/" className="header__link" name='aboutUs' onClick={handleClickScroll}>
                            О НАС
                        </NavLink>


                        <NavLink to="/" className="header__link" name='cases' onClick={handleClickScroll}>
                            КЕЙСЫ
                        </NavLink>

                        <NavLink to="/" className="header__link" name='contacts' onClick={handleClickScroll}>
                            КОНТАКТЫ
                        </NavLink>

                    </BrowserRouter>
                </div>

                <button className="header__contact-button" name='contacts' onClick={handleClickScroll}>СВЯЗАТЬСЯ С НАМИ</button>

            </header>
        </>
    );
}

export default Header;