// eslint-disable-next-line
import React from 'react';
import './cases.css';
import casesImg from '../../images/cases.png';



function Cases() {

    const textData = [
        {
            title: 'Работа с маркетплейсами',
            text: <p className='cases__description-paragraph'>
                Сбор данных с сайтов крупных маркетплейсов с последующим анализом количества продаж, изменений стоимости, спроса и предложения. 
                <br /><br />
                Построение системы на базе искусственного интеллекта для рекомендаций продавцам на площадках по категориям товаров к закупке и их оптимальному количеству.</p>
        },
        {
            title: 'Оценка рисков для страховых компаний',
            text: <p className='cases__description-paragraph'>
                Построение системы на базе искусственного интеллекта, получающей информацию о покупателе страховки, 
                и оценивающей по этой информации риски и оптимальную стоимость страхового предложения.</p>
        },
        {
            title: 'Медицинские проекты',
            text: <p className='cases__description-paragraph'>
                Анализ КТ-снимков технологиями компьютерного зрения с целью автоматического 
                определения рисков различных заболеваний без участия человека.</p>
        },
        {
            title: 'Синтез речи и речевые технологии',
            text: <p className='cases__description-paragraph'>Разработка голосовых моделей, генерирующих человеческую речь из текста.</p>
        },
        {
            title: 'Разработка сайтов',
            text: <p className='cases__description-paragraph'>Разработка сайтов различной сложности, от лендингов и сайтов-визиток до крупных корпоративных сайтов.</p>
        },
        {
            title: 'Разработка приложений',
            text: <p className='cases__description-paragraph'>Разработка мобильного приложения для крупнейшей энергосбытовой компании РФ. 
            Разработка корпоративного мобильного приложения и личного кабинета клиента в рамках цифровой трансформации и автоматизации кадровых процессов компании.</p>
        },
        {
            title: 'DevOps и администрирование',
            text: <p className='cases__description-paragraph'>Участие в администрировании и поддержании работы вычислительных кластеров и сетевой инфраструктуры различного масштаба.</p>
        },
    ]

    const [index, setIndex] = React.useState(0);
    const [currentText, setCurrentText] = React.useState(textData[0]);

    function handleNextClick() {
        setIndex((index + 1 === textData.length) ? (0) : (index + 1));
    };

    function handleBackClick() {
        setIndex((index === 0) ? (textData.length - 1) : (index - 1));
    }

    React.useEffect(() => {
        setCurrentText({title:'', text:<p className='cases__description-paragraph'></p>})
        setTimeout(() => {
            setCurrentText(textData[index]);
          }, 3);        
      }, [index]); 

    return (
        <>
            <div className='cases__block' id='cases'>
                <h2 className='cases__title'>КЕЙСЫ</h2>
                <div className='cases__information'>
                    <img alt='cases' className='cases__information-img' src={casesImg} />
                    <div className='cases__description'>
                        <h3 className='cases__description-title'>{currentText.title}</h3>
                        {currentText.text}

                        <div className='cases__switcher'>
                            <div className='cases__switcher-arrow cases__switcher-left' onClick={handleBackClick}></div>
                            <div className='cases__switcher-separator'></div>
                            <div className='cases__switcher-arrow cases__switcher-right' onClick={handleNextClick}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cases;