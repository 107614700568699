import React from 'react';
import Header from './landing/header';
import Main from './landing/main';
import About from './landing/about';
import Cases from './landing/cases';
import Contact from './landing/contact';
import Footer from './landing/footer';
import './App.css';

function App() {

  const handleClickScroll = (e) => {
    console.log(e.target.name);
    const element = document.getElementById(e.target.name);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

  };

  return (
    <div className="App">
      <Header
        handleClickScroll={handleClickScroll}
      />
      <Main />
      <About />
      <Cases />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
