// eslint-disable-next-line
import React from 'react';
import { BrowserRouter, NavLink } from "react-router-dom";
import './contact.css';
import phoneIcon from '../../images/contact_phone.svg';
import mailIcon from '../../images/contact_mail.svg';

function Contact() {

    return (
        <>
            <div className='contacts__block' id='contacts'>
                <h3 className='contacts__title'>
                    Хотите узнать больше?
                    <br />
                    Свяжитесь с нами, <br className='contacts__title_break' /> мы расскажем подробности.
                </h3>

                <div className='contacts__info'>
                    <BrowserRouter>
                        <div className='contacts__info-column'>
                            <img alt='phone-icon' className='contacts__info-phone-icon' src={phoneIcon} />
                            <div className='contacts__info-text'>Телефон
                                <NavLink
                                    to='#'
                                    className='contacts__info-text_bold'
                                    onClick={(e) => {
                                        window.location.href = "tel:89969749708";
                                        e.preventDefault();
                                    }}
                                >
                                    7-996-974-97-08
                                </NavLink>
                            </div>
                        </div>

                        <div className='contacts__info-column'>
                            <img alt='mail-icon' className='contacts__info-mail-icon' src={mailIcon} />


                            <div className='contacts__info-text'>Почта
                                <NavLink
                                    to='#'
                                    className='contacts__info-text_bold'
                                    onClick={(e) => {
                                        window.location.href = "mailto:neuromind@bk.ru";
                                        e.preventDefault();
                                    }}
                                >
                                    neuromind@bk.ru
                                </NavLink>
                            </div>
                        </div>
                    </BrowserRouter>
                </div>
            </div>
        </>
    );
}

export default Contact;